import React, { useContext, useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, NavLink } from "react-router-dom";

//MUI components
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    Drawer,
    CssBaseline,
    AppBar,
    Toolbar,
    List,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Container,
    Tooltip,
    Button,
} from "@material-ui/core";

//Icons
import {
    Menu as MenuIcon,
    Settings as SettingsIcon,
    DynamicFeed as DynamicFeedIcon,
    Spellcheck as SpellcheckIcon,
    ChatBubbleOutline as ChatIcon,
    AccountCircle as AccountCircleIcon,
    Assessment as AssessmentIcon,
    WhatsApp as WhatsAppIcon,
    ExitToApp as ExitToAppIcon,
    ArrowBack as ArrowBackIcon,
    AccountBox as AccountBoxIcon,
    QuestionAnswer as QuestionAnswerIcon,
    DeveloperBoard as DeveloperBoardIcon,
    Language as LanguageIcon,
    Memory as MemoryIcon,
} from "@material-ui/icons";

//Custom portal Components
import AuthPage from "./AuthPage";
import MessagesForm from "./MessagesForm";
import RegExForm from "./RegExForm";
import CatalogsTable from "./CatalogsTable";
import ProcessesTable from "./ProcessesTable";
import DashboardPage from "./DashboardPage";
import PortalSettings from "./PortalSettings";
import FAQpage from "./FAQpage";
import PLNpage from "./PLNpage";
import GenAiPage from "./GenAiPage";
import AppContext from "../Context/AppContext";
import Sendcampaignspage from "./sendCampaignsPage";
import MyAccountMenu from "../components/MyAccountComponents/MyAccountMenu";
import ChangeSubSelector from "../components/common/ChangeSubSelector";

//External Libraries
import Swal from "sweetalert2"; //https://sweetalert2.github.io

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        //flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        display: "true",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    hamburgerMenuIcon: {
        fontSize: "1.5em",
    },
    drawerHeader: {
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    navBarLogo: {
        justifySelf: "start",
        margin: "20px",
        //cursor: 'pointer',
        width: "200px",
    },
    navBarMenu: {
        display: "none",
        gridTemplateColumns: "repeat(5, auto)",
        listStyle: "none",
        textAlign: "center",
        width: "100vw",
        justifyContent: "flex-start",
        fontSize: "1rem",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    iconsNavBarMenu: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
            justifyContent: "flex-end",
        },
    },
    link: {
        textDecoration: "none",
        color: theme.palette.text.primary,
        [theme.breakpoints.up("md")]: {
            color: "white",
        },
        "&:hover": {
            borderBottom: `.35rem solid ${theme.palette.primary.light} b0`,
        },
        padding: "7px",
    },
    list: {
        padding: "0 5px",
    },
    clickedNavButton: {
        [theme.breakpoints.up("md")]: {
            borderBottom: ".35rem solid " + theme.palette.primary.light,
        },
        borderBottom: "none",
    },
    container: {
        marginTop: "20px",
        marginBottom: "70px",
        [theme.breakpoints.up("sm")]: {
            paddingRight: "10%",
            paddingLeft: "10%",
        },
    },
    Button: {
        color: "white",
        padding: 0,
        textTransform: "none",
        fontWeight: "400",
    },
    iconButton: {
        color: "white",
        padding: "0 10px",
        textTransform: "none",
        fontWeight: "400",
        minWidth: "0",
        display: "block",
    },
    Icon: {
        fontSize: "2em",
    },
    Footer: {
        backgroundColor: "#27315d",
        minWidth: "100vw",
        minHeight: "80px",
        [theme.breakpoints.down("641px")]: {
            minHeight: "3.1em",
        },
        [theme.breakpoints.down("sm")]: {
            minHeight: "4.5em",
        },
    },
}));

export default function PersistentDrawerLeft() {
    const classes = useStyles();
    const theme = useTheme();
    const matchesUpMd = useMediaQuery(theme.breakpoints.up("md"));

    const [open, setOpen] = useState(false);
    const [openRightMenu, setOpenRightMenu] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    //Text menu items
    const [currentMenuItems, setCurrentMenuItems] = useState([]);
    //Icon menu items
    const [currentIconMenuItems, setCurrentIconMenuItems] = useState([]);
    //Hamburger menu items
    const [currentHamburgerMenuItems, setCurrentHamburgerMenuItems] = useState([]);
    //Routes
    const [currentPortalRoutes, setCurrentPortalRoutes] = useState([]);

    //change sub state
    const [currentSub, setCurrentSub] = useState("");
    const [subSelectorIsOpen, setSubSelectorIsOpen] = useState(false);
    const [openSelectorToolTip, setOpenSelectorToolTip] = useState(false);

    const context = useContext(AppContext);

    const {
        userLoggedIn,
        getStorageData,
        logOutUser,
        updateSubscriptionData,
        updateContextAttribute,
    } = context;

    let storageData = getStorageData([
        "subscriptionModule",
        "exclusiveServices",
        "currentSubscription",
        "subscriptionId",
        "remeberAuthCredentials",
    ]);

    let authData = getStorageData([
        "email",
        "name",
        "lastName",
        "organizationName",
        "subscriptionId",
        "token",
    ]);

    useEffect(() => {
        //Save on context the matchesUpMd
        updateContextAttribute("matchesUpMd", matchesUpMd);
    }, [matchesUpMd]);

    //Did mount
    useEffect(() => {
        switch (true) {
            case !userLoggedIn:
                setCurrentPortalRoutes(notLoggedRoutes);
                if (window.location.pathname !== "/create-account") {
                    document.body.classList.add("hide-overflow");
                }
                return;
            case userLoggedIn && storageData.exclusiveServices.processNaturalLenguage:
                setCurrentPortalRoutes(excluisvePLNRoutes);
                setCurrentMenuItems([]);
                setCurrentIconMenuItems(PLNIconMenuItems);
                setCurrentHamburgerMenuItems(hamburgerPLNmenuItems);
                break;
            case userLoggedIn && storageData.exclusiveServices.sendCampaigns:
                setCurrentPortalRoutes(excluisveCampaignsRoutes);
                setCurrentMenuItems([]);
                setCurrentIconMenuItems(CampaignsIconMenuItems);
                setCurrentHamburgerMenuItems(hamburgerCampaignsMenuItems);
                break;
            case userLoggedIn:
                setCurrentPortalRoutes(loggedInRoutes);
                setCurrentMenuItems(MenuItems);
                setCurrentIconMenuItems(menuIconItems);
                setCurrentHamburgerMenuItems(hamburgerMenuItems);
                break;
            default:
                break;
        }
        document.body.classList.remove("hide-overflow");
    }, [userLoggedIn, context]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setCurrentSub(storageData?.currentSubscription?.name ?? "");
    }, [storageData]);

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const changeOpenRighMenu = (openRightMenu) => {
        setOpenRightMenu({
            ...openRightMenu,
        });
    };

    const changeSubscription = async (targetValue) => {
        let alert = await Swal.fire({
            title: "¿Deseas cambiar de suscripción?",
            text: "Se perdera cualquier cambio no guardado.",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el eliminado del usuario
        if (alert.isDenied || alert.isDismissed) {
            return;
        }
        let selectedSub = storageData.subscriptionId.find(
            (subscription) => subscription.name === targetValue
        );
        setCurrentSub(targetValue);
        updateSubscriptionData(authData, selectedSub);
        if (storageData.remeberAuthCredentials) {
            localStorage.setItem("currentSubscription", JSON.stringify(selectedSub));
            return;
        }
        sessionStorage.setItem("currentSubscription", JSON.stringify(selectedSub));
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    //=====================DEFAULT MENU ITEMS==========================================================

    //Only text menu items
    const MenuItems = [
        { nameDesk: "Mensajes", nameMobile: "Mensajes", url: "/config", title: "" },
        { nameDesk: "Tipos de datos", nameMobile: "Tipos de datos", url: "/regex", title: "" },
        { nameDesk: "Catálogos", nameMobile: "Catálogos", url: "/catalogs", title: "" },
    ];

    //Only icon menu items
    const menuIconItems = [
        { url: "/faq", title: "FAQ", icon: <QuestionAnswerIcon className={classes.Icon} /> },
        // {
        //     url: "/pln",
        //     title: "PLN",
        //     icon: <LanguageIcon className={classes.Icon} />,
        //     onclick: storageData.subscriptionModule?.processNaturalLenguage
        //         ? null
        //         : () =>
        //               Swal.fire({
        //                   icon: "info",
        //                   title: "Este módulo no esta disponible en tu suscripción",
        //                   text: "Si deseas activar algún módulo, por favor, ponte en contacto con atención al cliente",
        //               }),
        // },

        { url: "/genai", title: "GenAI", icon: <MemoryIcon className={classes.Icon} /> },

        { url: "/dashboard", title: "Reporte", icon: <AssessmentIcon className={classes.Icon} /> },
        {
            url: "/campaigns",
            title: "Envio de Campañas",
            icon: <WhatsAppIcon className={classes.Icon} />,
            onclick: storageData.subscriptionModule?.sendCampaigns
                ? null
                : () =>
                      Swal.fire({
                          icon: "info",
                          title: "Este módulo no esta disponible en tu suscripción",
                          text: "Si deseas activar algún módulo, por favor, ponte en contacto con atención al cliente",
                      }),
        },
        //{url: '', title: 'Ajustes', icon: <SettingsIcon className={classes.Icon} />, onclick: () => changeOpenRighMenu({settings: true })},
        {
            url: "",
            title: "Cuenta",
            icon: <AccountCircleIcon className={classes.Icon} />,
            onclick: handleMenuOpen,
        },
    ];

    //Default hamburger menu items
    const hamburgerMenuItems = [
        { name: "Flujos", url: "/flows", icon: <DeveloperBoardIcon fontSize="small" /> },
        { name: "Mensajes", url: "/config", icon: <ChatIcon fontSize="small" /> },
        { name: "Tipos de datos", url: "/regex", icon: <SpellcheckIcon fontSize="small" /> },
        { name: "Catálogos", url: "/catalogs", icon: <DynamicFeedIcon fontSize="small" /> },
        { name: "Faq", url: "/faq", icon: <QuestionAnswerIcon className={classes.Icon} /> },
        //{ name: "PLN", url: "/pln", icon: <LanguageIcon className={classes.Icon} /> },
        { name: "GenAI", url: "/genai", icon: <MemoryIcon className={classes.Icon} /> },
        { name: "Reporte", url: "/dashboard", icon: <AssessmentIcon className={classes.Icon} /> },
        {
            name: "Envio de campañas",
            url: "/campaigns",
            icon: <WhatsAppIcon className={classes.Icon} />,
            onclick: storageData.subscriptionModule?.sendCampaigns
                ? null
                : () =>
                      Swal.fire({
                          icon: "info",
                          title: "Este módulo no esta disponible en tu suscripción",
                          text: "Si deseas activar algún módulo, por favor, ponte en contacto con atención al cliente",
                      }),
        },
        {
            name: "Mi cuenta",
            icon: <AccountCircleIcon className={classes.Icon} />,
            onclick: () => changeOpenRighMenu({ account: true }),
        },
        {
            name: "Mi suscripción",
            icon: <AccountBoxIcon className={classes.Icon} />,
            onclick: () => changeOpenRighMenu({ suscription: true }),
        },
        {
            name: "Ajustes",
            icon: <SettingsIcon className={classes.Icon} />,
            onclick: () => changeOpenRighMenu({ settings: true }),
        },
        {
            name: "Cerrar sesión",
            icon: <ExitToAppIcon className={classes.Icon} />,
            onclick: () => logOutUser(),
        },
    ];

    //==================================PLN MENU ITEMS=======================================================
    const PLNIconMenuItems = [
        { url: "/pln", title: "PLN", icon: <LanguageIcon className={classes.Icon} /> },
        {
            url: "",
            title: "Cuenta",
            icon: <AccountCircleIcon className={classes.Icon} />,
            onclick: handleMenuOpen,
        },
    ];

    const hamburgerPLNmenuItems = [
        { name: "PLN", url: "/pln", icon: <LanguageIcon className={classes.Icon} /> },
        {
            name: "Mi cuenta",
            icon: <AccountCircleIcon className={classes.Icon} />,
            onclick: () => changeOpenRighMenu({ account: true }),
        },
        {
            name: "Mi suscripción",
            icon: <AccountBoxIcon className={classes.Icon} />,
            onclick: () => changeOpenRighMenu({ suscription: true }),
        },
        {
            name: "Ajustes",
            icon: <SettingsIcon className={classes.Icon} />,
            onclick: () => changeOpenRighMenu({ settings: true }),
        },
        {
            name: "Cerrar sesión",
            icon: <ExitToAppIcon className={classes.Icon} />,
            onclick: () => logOutUser(),
        },
    ];

    //==================================CAMPAIGNS MENU ITEMS=======================================================
    const CampaignsIconMenuItems = [
        { url: "/campaigns", title: "Campaigns", icon: <WhatsAppIcon className={classes.Icon} /> },
        { url: "/dashboard", title: "Reporte", icon: <AssessmentIcon className={classes.Icon} /> },
        {
            url: "",
            title: "Cuenta",
            icon: <AccountCircleIcon className={classes.Icon} />,
            onclick: handleMenuOpen,
        },
    ];

    const hamburgerCampaignsMenuItems = [
        {
            name: "Envio de campañas",
            url: "/campaigns",
            icon: <WhatsAppIcon className={classes.Icon} />,
            onclick: storageData.subscriptionModule?.sendCampaigns
                ? null
                : () =>
                      Swal.fire({
                          icon: "info",
                          title: "Este módulo no esta disponible en tu suscripción",
                          text: "Si deseas activar algún módulo, por favor, ponte en contacto con atención al cliente",
                      }),
        },
        { name: "Reporte", url: "/dashboard", icon: <AssessmentIcon className={classes.Icon} /> },
        {
            name: "Mi cuenta",
            icon: <AccountCircleIcon className={classes.Icon} />,
            onclick: () => changeOpenRighMenu({ account: true }),
        },
        {
            name: "Mi suscripción",
            icon: <AccountBoxIcon className={classes.Icon} />,
            onclick: () => changeOpenRighMenu({ suscription: true }),
        },
        {
            name: "Ajustes",
            icon: <SettingsIcon className={classes.Icon} />,
            onclick: () => changeOpenRighMenu({ settings: true }),
        },
        {
            name: "Cerrar sesión",
            icon: <ExitToAppIcon className={classes.Icon} />,
            onclick: () => logOutUser(),
        },
    ];

    const notLoggedRoutes = (
        <>
            <Switch>
                <Route exact path="/login">
                    <AuthPage />
                </Route>
                <Route exact path="/search-account">
                    <AuthPage />
                </Route>
                <Route exact path="/create-account">
                    <AuthPage />
                </Route>
                <Route exact path="/reset-password">
                    <AuthPage />
                </Route>
                <Route>
                    <Redirect to="/login" />
                </Route>
            </Switch>
        </>
    );

    const loggedInRoutes = (
        <Switch>
            <Route exact path="/flows">
                <Container className={classes.container}>
                    <ProcessesTable />
                </Container>
            </Route>
            <Route exact path="/change-password">
                <Container className={classes.container}>
                    <AuthPage />
                </Container>
            </Route>
            <Route exact path="/config">
                <Container className={classes.container}>
                    <MessagesForm contextMessages={context.messagesTemp ?? []} />
                </Container>
            </Route>
            <Route exact path="/regex">
                <Container className={classes.container}>
                    <RegExForm />
                </Container>
            </Route>
            <Route exact path="/catalogs">
                <Container className={classes.container}>
                    <CatalogsTable />
                </Container>
            </Route>
            <Route exact path="/dashboard">
                <DashboardPage />
            </Route>
            {storageData.subscriptionModule?.sendCampaigns && (
                <Route exact path="/campaigns">
                    <Container className={classes.container}>
                        <Sendcampaignspage />
                    </Container>
                </Route>
            )}
            <Route exact path="/faq">
                <Container className={classes.container}>
                    <FAQpage />
                </Container>
            </Route>
            {/* <Route exact path="/pln">
                <Container className={classes.container}>
                    <PLNpage />
                </Container>
            </Route> */}
            <Route exact path="/genai">
                <Container className={classes.container}>
                    <GenAiPage />
                </Container>
            </Route>

            <Route>
                <Redirect to="/flows" />
            </Route>
        </Switch>
    );

    const excluisvePLNRoutes = (
        <Switch>
            <Route exact path="/pln">
                <Container className={classes.container}>
                    <PLNpage />
                </Container>
            </Route>
            <Route exact path="/change-password">
                <Container className={classes.container}>
                    <AuthPage />
                </Container>
            </Route>
            <Route>
                <Redirect to="/pln" />
            </Route>
        </Switch>
    );

    const excluisveCampaignsRoutes = (
        <Switch>
            <Route exact path="/campaigns">
                <Container className={classes.container}>
                    <Sendcampaignspage />
                </Container>
            </Route>
            <Route exact path="/dashboard">
                <DashboardPage />
            </Route>
            <Route exact path="/change-password">
                <Container className={classes.container}>
                    <AuthPage />
                </Container>
            </Route>
            <Route>
                <Redirect to="/campaigns" />
            </Route>
        </Switch>
    );

    return (
        <React.Fragment>
            <Router>
                {/* ====== Ajustes de FAQ ======= */}
                <Drawer
                    anchor={matchesUpMd ? "right" : "left"}
                    open={openRightMenu.settings}
                    onClose={() => changeOpenRighMenu({ settings: false })}
                >
                    <PortalSettings
                        openSettings={true}
                        onClose={() => changeOpenRighMenu({ settings: false })}
                    />
                </Drawer>
                {/* ====== Mi Suscripcion ======= */}
                <Drawer
                    anchor={matchesUpMd ? "right" : "left"}
                    open={openRightMenu.suscription}
                    onClose={() => changeOpenRighMenu({ suscription: false })}
                >
                    <PortalSettings
                        openSuscription={true}
                        onClose={() => changeOpenRighMenu({ suscription: false })}
                    />
                </Drawer>
                {/* ====== Mi Cuenta ======= */}
                <Drawer
                    anchor={matchesUpMd ? "right" : "left"}
                    open={openRightMenu.account}
                    onClose={() => changeOpenRighMenu({ account: false })}
                >
                    <PortalSettings
                        openAccount={true}
                        openDrawerChangePassword={() =>
                            changeOpenRighMenu({ changePassword: true })
                        }
                        openDrawerManagePermissions={() =>
                            changeOpenRighMenu({ managePermitions: true })
                        }
                        onClose={() => changeOpenRighMenu({ account: false })}
                    />
                </Drawer>
                {/* ====== Cambio de contraseña ======= */}
                <Drawer
                    anchor={matchesUpMd ? "right" : "left"}
                    open={openRightMenu.changePassword}
                    onClose={() => changeOpenRighMenu({ changePassword: false })}
                >
                    <PortalSettings
                        openChangePassword={true}
                        onClose={() => changeOpenRighMenu({ changePassword: false })}
                    />
                </Drawer>
                {/* ====== Manejo de Permisos ======= */}
                <Drawer
                    anchor={matchesUpMd ? "right" : "left"}
                    open={openRightMenu.managePermitions}
                    onClose={() => changeOpenRighMenu({ managePermitions: false })}
                >
                    <PortalSettings
                        openManagePermissions={true}
                        onClose={() => changeOpenRighMenu({ managePermitions: false })}
                    />
                </Drawer>
                {/* ========= menu de la cuenta ======== */}
                {userLoggedIn && (
                    <MyAccountMenu
                        anchorEl={anchorEl}
                        handleMenuClose={handleMenuClose}
                        changeOpenRighMenu={changeOpenRighMenu}
                    />
                )}
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar position="fixed">
                        <Toolbar>
                            {userLoggedIn && (
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    className={clsx(classes.menuButton)}
                                >
                                    <MenuIcon className={clsx(classes.hamburgerMenuIcon)} />
                                </IconButton>
                            )}
                            {userLoggedIn && (
                                <Link to="/">
                                    <img
                                        src="/intebot_logo.svg"
                                        alt="Logo Intebot"
                                        className={classes.navBarLogo}
                                        width={100}
                                    />
                                </Link>
                            )}
                            {/* ====== main text menu =======  */}
                            {userLoggedIn && (
                                <List className={classes.navBarMenu}>
                                    {currentMenuItems.map((item, index) => (
                                        <li key={index} className={classes.list}>
                                            {item.title ? (
                                                <Tooltip title={item.title}>
                                                    <Button className={classes.Button}>
                                                        {item.icon}&nbsp;
                                                        {item.nameDesk}
                                                    </Button>
                                                </Tooltip>
                                            ) : (
                                                <NavLink
                                                    className={classes.link}
                                                    to={item.url}
                                                    activeClassName={classes.clickedNavButton}
                                                >
                                                    {item.icon}&nbsp;
                                                    {item.nameDesk}
                                                </NavLink>
                                            )}
                                        </li>
                                    ))}
                                </List>
                            )}
                            {/* ======== only icons menu ====== */}
                            {userLoggedIn && (
                                <List className={classes.iconsNavBarMenu}>
                                    {currentIconMenuItems.map((item, index) => (
                                        <li key={index} className={classes.list}>
                                            <Tooltip title={item.title}>
                                                {item.onclick ? (
                                                    <Button
                                                        className={classes.iconButton}
                                                        onClick={item.onclick}
                                                    >
                                                        {item.icon}
                                                    </Button>
                                                ) : (
                                                    <NavLink
                                                        className={classes.iconButton}
                                                        to={item.url}
                                                        activeClassName={classes.clickedNavButton}
                                                    >
                                                        {item.icon}
                                                    </NavLink>
                                                )}
                                            </Tooltip>
                                        </li>
                                    ))}
                                </List>
                            )}
                        </Toolbar>
                    </AppBar>
                    {!matchesUpMd ? (
                        <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
                            <div className={classes.drawerHeader}>
                                <IconButton onClick={handleDrawerClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </div>
                            <Divider />
                            <ChangeSubSelector
                                subSelectorIsOpen={subSelectorIsOpen}
                                setOpenSelectorToolTip={setOpenSelectorToolTip}
                                openSelectorToolTip={openSelectorToolTip}
                                currentSub={currentSub}
                                setSubSelectorIsOpen={setSubSelectorIsOpen}
                                changeSubscription={changeSubscription}
                                subscriptionId={storageData.subscriptionId}
                            />
                            {/* ===== Hamburger menu items ===== */}
                            <List>
                                {currentHamburgerMenuItems.map((item, index) => (
                                    <NavLink
                                        key={index}
                                        className={classes.link}
                                        to={item.url ?? window.location.pathname}
                                        onClick={() => {
                                            handleDrawerClose();
                                        }}
                                        activeClassName={classes.clickedNavButton}
                                    >
                                        <ListItem button onClick={item.onclick}>
                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    </NavLink>
                                ))}
                            </List>
                        </Drawer>
                    ) : (
                        <React.Fragment />
                    )}
                    <main className={clsx(classes.content)}>
                        <div className={classes.drawerHeader} />
                        <img src="/separador.svg" alt="Separador de viñeta" width={"100%"} />
                        {currentPortalRoutes}
                    </main>
                </div>
                {!userLoggedIn && <footer className={classes.Footer}> </footer>}
            </Router>
        </React.Fragment>
    );
}
