import React, { useState, useEffect, useContext } from "react";

// MUI components
import {
    Grid,
    Tooltip,
    CircularProgress,
    IconButton,
    Button,
    Button as MuiButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    TextField,
    Checkbox,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Icons
import { ReactComponent as TrashCan } from "../Icons/trashcanIcon.svg";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AgregarIcono from "../Icons/AgregarIcono";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

// Context and components
import AppContext from "../Context/AppContext";
import CloseButton from "../components/common/CloseButton";
import CustomButton from "../components/common/CustomButton";
import RefreshButton from "../components/common/RefreshButton";
import FooterControls from "../components/FooterComponents/FooterControls";

// External Libraries
import Swal from "sweetalert2";
import { DropzoneArea } from "material-ui-dropzone";
import isURL from "validator/lib/isURL";

// CSS
import "./StyledPages/GenAiPage.css";

// Data and helpers
import {
    openAIAcceptedFiles,
    faqUploadTypes,
    errorHelper,
} from "../components/IntebotCatalog";
import { openAIActions, blobStorage } from "../components/DataFunctions";

//Intebot Data Actions
import { intebotDataActions } from "../components/DataFunctions";

// Custom styles
const ButtonFilled = withStyles((theme) => ({
    root: {
        background: theme.palette.primary.dark,
        borderRadius: 25,
        color: "white",
        height: 36,
        padding: "0 30px",
        "&:hover": {
            background: theme.palette.primary.light,
        },
        "&$disabled": {
            color: "white",
            background: theme.palette.other.gray,
        },
    },
    disabled: {},
    label: {
        textTransform: "none",
    },
}))(MuiButton);

const GenAiPage = () => {
    const { fetchKnowledgeBaseSources, messages, getStorageData } =
        useContext(AppContext);
    const { subscriptionModule, token } = getStorageData([
        "subscriptionModule",
        "token",
    ]);
    const context = useContext(AppContext);

    // State
    const [settings, setSettings] = useState({});
    const [openAiFiles, setOpenAiFiles] = useState([]);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [textGenerationFiles, setTextGenerationFiles] = useState([]);
    const [uploadType, setUploadType] = useState("file");
    const [uploadURL, setUploadURL] = useState("");
    const [uploadErrors, setUploadErrors] = useState({
        uploadFile: true,
        uploadURL: false,
    });
    const [uploadErrorHelper, setUploadErrorHelper] = useState({
        uploadFile: errorHelper.emptyField,
        uploadURL: errorHelper.removeError,
    });
    const [trainingStatus, setTrainingStatus] = useState("");
    const [elapsedTime, setElapsedTime] = useState("");
    const [loading, setLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [previousValue, setPreviousValue] = useState({
        settings: {},
    });

    const clearState = () => {
        setUploadErrors({
            uploadFile: true,
            uploadURL: false,
        });
        setUploadErrorHelper({
            uploadFile: errorHelper.emptyField,
            uploadURL: errorHelper.removeError,
        });
        setUploadURL("");
        setTextGenerationFiles([]);
        setUploadType("file");
        setTrainingStatus("");
        setElapsedTime("");
        setHasChanges(false);
    };

    useEffect(() => {
        clearState();
    }, [openUploadDialog]);

    const refreshFunction = async () => {
        setLoading(true);
        await fetchKnowledgeBaseSources();
        await getTextGenerationFiles();

        const settingsCopy = JSON.parse(JSON.stringify(messages));
        setSettings(settingsCopy);
        setLoading(false);
    };

    useEffect(() => {
        refreshFunction();
        setPreviousValue({
            settings: JSON.parse(JSON.stringify(messages)),
        });
    }, []);

    const onSave = async () => {
        const {
            updateContextAttribute,
            fileFAQToSaveHasSavedChanges,
            fileFAQToDeleteHasSavedChanges,
            sendFilesToBlob,
            fetchKnowledgeBaseSources,
            settingsHasChanges,
        } = context;

        if (settingsHasChanges) {
            //Guardamos los cambios en el context
            updateContextAttribute("messages", settings);
            updateContextAttribute("messagesHasChanges", false);
            updateContextAttribute("messagesTemp", settings);
            updateContextAttribute("messagesHasSavedChanges", true);
            updateContextAttribute("settingsHasSavedChanges", true);

            //Guardamos los cambios en el servicio a base de datos de test
            updateContextAttribute("loadingDialog", true);
            updateContextAttribute(
                "LoadingMessage",
                "Estamos realizando la conexión con el servicio."
            );
            settings.token = token;
            let res = await intebotDataActions(
                settings,
                "updateConfigurationVariables&enviroment=Test",
                "BotConfigurationOperations"
            );
            let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
            updateContextAttribute("loadingDialog", false);
            updateContextAttribute("LoadingMessage", "");
            switch (responseCode) {
                case 0:
                    updateContextAttribute("messagesHasChanges", false);
                    updateContextAttribute("messagesHasSavedChanges", true);
                    updateContextAttribute("settingsHasChanges", false);
                    updateContextAttribute("settingsHasSavedChanges", true);
                    let alert = {
                        open: true,
                        severity: "success",
                        message: "Configuración guardada correctamente",
                    };
                    updateContextAttribute("alert", alert);
                    setHasChanges(false);
                    break;
                case 2:
                    //El usuario no tiene permisos para realizar esta acción
                    let alert2 = {
                        open: true,
                        severity: "error",
                        message:
                            "Tu suscripción ha caducado, por favor ponte en contacto con el soporte para actualizar tu plan.",
                    };
                    updateContextAttribute("alert", alert2);
                    break;
                case 3:
                    Swal.fire({
                        icon: "info",
                        title: "Tu cuenta no tiene permisos para editar esta suscripción",
                        text: "Si deseas hacer ediciones ponte en contacto con el administrador de la suscripción.",
                    });
                    break;

                default:
                    //Error en el servicio
                    let alertError = {
                        open: true,
                        severity: "error",
                        message:
                            "Ocurrió un error al guardar la configuración, por favor intenta de nuevo.",
                    };
                    updateContextAttribute("alert", alertError);
                    break;
            }
        }
    };

    const onCancel = () => {
        setSettings(previousValue.settings);
        setHasChanges(false);
    };

    const convertTo24HourFormat = (time) => {
        const [timePart, modifier] = time.split(" ");
        let [hours, minutes, seconds] = timePart.split(":");

        if (hours === "12") {
            hours = "00";
        }

        if (modifier === "PM") {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}:${seconds}`;
    };

    const calculateElapsedTime = (processDate, status) => {
        if (status === "succeeded" || status === "Needs Training") {
            return setElapsedTime("");
        }

        const now = new Date();
        const [hours, minutes, seconds] =
            convertTo24HourFormat(processDate).split(":");
        const processTime = new Date(now);
        processTime.setHours(hours);
        processTime.setMinutes(minutes);
        processTime.setSeconds(seconds);

        const diff = now - processTime;

        if (diff < 0) {
            return;
        }

        const diffInMinutes = Math.floor(diff / 60000);
        const diffInSeconds = Math.floor((diff % 60000) / 1000);
        let timeElapsed = `${diffInMinutes}:${
            diffInSeconds < 10 ? "0" : ""
        }${diffInSeconds}`;

        setElapsedTime(timeElapsed);
    };

    const getTextGenerationFiles = async (noStatus) => {
        const { updateContextAttribute } = context;
        if (!subscriptionModule.textGeneration) return;
        let res = await openAIActions(null, "getFiles");
        let responseCode = res?.data?.response?.code
            ? parseInt(res.data.response.code)
            : 99;

        switch (responseCode) {
            case 0:
                let files = res?.data?.response.files;
                if (!noStatus) {
                    getStatusOpenAIFiles();
                }
                setOpenAiFiles(files);
                setElapsedTime("");
                break;

            default:
                let alertError = {
                    open: true,
                    severity: "error",
                    message:
                        "Ocurrió un error al obtener los archivos de generación de texto.",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
    };

    const getStatusOpenAIFiles = async () => {
        const { updateContextAttribute, sleep } = context;
        let res = await openAIActions(null, "getStatus");

        let responseCode = res?.data?.response?.code
            ? parseInt(res.data.response.code)
            : 99;

        switch (responseCode) {
            case 0:
                let status = res?.data?.response?.status;
                let processDate = res?.data?.response?.processDate || "";
                let urlName = res?.data?.response?.urlName || "";

                let statusColor = "";
                let statusDetail = "";
                let statusAlias = "";
                calculateElapsedTime(processDate, status);

                switch (status) {
                    case "succeeded":
                        statusAlias = "Entrenamiento completado";
                        statusColor = "#44AB3F";
                        statusDetail =
                            "El modelo está listo para ser utilizado.";
                        break;
                    case "training":
                        statusAlias = "Entrenamiento en proceso";
                        statusColor = "spinner";
                        statusDetail =
                            "El modelo está en proceso de entrenamiento, puede tardar varios minutos.";
                        break;
                    case "Needs Training":
                        statusAlias = "Necesita entrenamiento";
                        statusColor = "#e48922";
                        statusDetail =
                            "Da click en el botón de entrenar para iniciar el proceso de entrenamiento.";
                        break;
                    case "Uploading URL":
                        statusAlias = `Cargando sitio web: ${urlName}`;
                        statusColor = "spinner";
                        statusDetail =
                            "Cargando el sitio web, espera un momento.";
                        break;
                    case "Hubo un error al entrenar/subir":
                        statusAlias =
                            "Error al procesar el archivo, inténtalo de nuevo.";
                        statusColor = "#e22e2e";
                        statusDetail =
                            "Ocurrió un error al procesar el archivo. Inténtalo de nuevo.";
                        break;
                    default:
                        statusAlias = "Error";
                        statusColor = "#e22e2e";
                        statusDetail =
                            "Ocurrió un error al procesar el archivo.";
                        break;
                }
                let statusObj = {
                    status: statusAlias,
                    statusColor: statusColor,
                    statusDetail: statusDetail,
                    processDate: processDate,
                    urlName: urlName,
                };

                setTrainingStatus(statusObj);
                if (status === "training" || status === "Uploading URL") {
                    var interval = setInterval(() => {
                        calculateElapsedTime(processDate, status);
                    }, 1000);

                    clearInterval(interval);
                    await sleep(5000);
                    await getStatusOpenAIFiles();
                }

                if (status === "succeeded" || status === "Needs Training") {
                    setElapsedTime("");
                    getTextGenerationFiles(true);
                }

                return statusObj;
            default:
                let alertError = {
                    open: true,
                    severity: "error",
                    message:
                        "Ocurrió un error al obtener los archivos de generación de texto.",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
    };

    const handleCloseDialog = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenUploadDialog(false);
    };

    function readFile(file) {
        if (!file) return;
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function (event) {
                resolve(event.target.result);
            };

            reader.onerror = function (event) {
                reject(event.target.error);
            };

            reader.readAsDataURL(file);
        });
    }

    const handleFileUpload = async (targetName, targetValue) => {
        let uploadErrorsCopy = JSON.parse(JSON.stringify(uploadErrors));
        let uploadErrorHelperCopy = JSON.parse(
            JSON.stringify(uploadErrorHelper)
        );
        let uploadURLCopy = JSON.parse(JSON.stringify(uploadURL));
        let textGenerationFilesCopy = JSON.parse(
            JSON.stringify(textGenerationFiles)
        );
        let uploadTypeCopy = JSON.parse(JSON.stringify(uploadType));

        switch (targetName) {
            case "uploadType":
                uploadTypeCopy = targetValue;
                textGenerationFilesCopy = "";
                uploadURLCopy = "";
                if (targetValue === "url") {
                    uploadErrorHelperCopy.uploadFile = errorHelper.removeError;
                    uploadErrorsCopy.uploadFile = false;
                    uploadErrorHelperCopy.uploadURL = errorHelper.emptyField;
                    uploadErrorsCopy.uploadURL = true;
                    break;
                }
                if (targetValue === "file") {
                    uploadErrorHelperCopy.uploadURL = errorHelper.removeError;
                    uploadErrorsCopy.uploadURL = false;
                    uploadErrorHelperCopy.uploadFile = errorHelper.emptyField;
                    uploadErrorsCopy.uploadFile = true;
                    break;
                }

                break;

            case "uploadFile":
                if (!targetValue) {
                    uploadErrorsCopy[targetName] = true;
                    uploadErrorHelperCopy[targetName] = errorHelper.emptyField;
                    break;
                }
                let fileBase64Copy = await readFile(targetValue);
                let fileName = targetValue.name
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(/ /g, "_");
                let fileType = targetValue.type;
                let uploadedFileCopy = {
                    name: fileName,
                    body: fileBase64Copy.split(",")[1],
                    type: fileType,
                    length: targetValue.size,
                };
                textGenerationFilesCopy = uploadedFileCopy;
                uploadErrorsCopy[targetName] = false;
                uploadErrorHelperCopy[targetName] = errorHelper.removeError;

                break;
            case "uploadURL":
                uploadURLCopy = targetValue;

                if (!isURL(targetValue)) {
                    uploadErrorsCopy[targetName] = true;
                    uploadErrorHelperCopy[targetName] = errorHelper.validURL;
                    break;
                }

                if (!targetValue) {
                    uploadErrorsCopy[targetName] = true;
                    uploadErrorHelperCopy[targetName] = errorHelper.emptyField;
                    break;
                }
                uploadErrorsCopy[targetName] = false;
                uploadErrorHelperCopy[targetName] = errorHelper.removeError;
                break;

            default:
                break;
        }
        setUploadErrors(uploadErrorsCopy);
        setUploadErrorHelper(uploadErrorHelperCopy);
        setUploadURL(uploadURLCopy);
        setTextGenerationFiles(textGenerationFilesCopy);
        setUploadType(uploadTypeCopy);
    };

    const sendFilesToBlob = async () => {
        const { updateContextAttribute } = context;
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute(
            "LoadingMessage",
            "Estamos realizando la conexión con el servicio."
        );
        let responseCode = 99;

        if (uploadType === "url") {
            let data = {
                url: uploadURL,
            };
            let res = await openAIActions(data, "uploadURL");
            responseCode = res?.data?.response?.code
                ? parseInt(res.data.response.code)
                : 99;
        }

        if (uploadType === "file" && textGenerationFiles.length > 0) {
            let res = await blobStorage(
                textGenerationFiles,
                "uploadFile&uploadType=openai"
            );
            responseCode = res?.data?.code ? parseInt(res.data.code) : 99;
        }

        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");

        switch (responseCode) {
            case 0:
                let alert = {
                    open: true,
                    severity: "success",
                    message: "Archivos guardados correctamente",
                };
                updateContextAttribute("alert", alert);
                setTextGenerationFiles([]);
                setOpenUploadDialog(false);
                getTextGenerationFiles();

                break;

            default:
                let alertError = {
                    open: true,
                    severity: "error",
                    message:
                        "Ocurrió un error al guardar los archivos, por favor intenta de nuevo.",
                };
                updateContextAttribute("alert", alertError);

                break;
        }
    };

    const deleteFileFromOpenAI = async (fileName) => {
        let askConfirm = await Swal.fire({
            title: "¿Estás seguro que deseas eliminar el archivo?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });

        if (askConfirm.isDenied || askConfirm.isDismissed) {
            return;
        }

        const { updateContextAttribute } = context;
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute(
            "LoadingMessage",
            "Estamos realizando la conexión con el servicio."
        );
        let data = {
            name: fileName,
        };

        let res = await blobStorage(data, "deleteFile&uploadType=openai");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99;
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");

        switch (responseCode) {
            case 0:
                let alert = {
                    open: true,
                    severity: "success",
                    message: "Archivo eliminado correctamente",
                };
                updateContextAttribute("alert", alert);
                getTextGenerationFiles();
                break;

            default:
                let alertError = {
                    open: true,
                    severity: "error",
                    message:
                        "Ocurrió un error al eliminar el archivo, por favor intenta de nuevo.",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
    };

    const trainSearchIndex = async () => {
        let askConfirm = await Swal.fire({
            title: "¿Estás seguro que deseas iniciar el entrenamiento?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        if (askConfirm.isDenied || askConfirm.isDismissed) {
            return;
        }

        const { updateContextAttribute } = context;
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute(
            "LoadingMessage",
            "Estamos realizando la conexión con el servicio."
        );
        let res = await openAIActions(null, "train");
        let responseCode = res?.data?.response?.code
            ? parseInt(res.data.response.code)
            : 99;
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        switch (responseCode) {
            case 0:
                let alert = {
                    open: true,
                    severity: "success",
                    message: "Entrenamiento iniciado correctamente",
                };
                updateContextAttribute("alert", alert);
                getStatusOpenAIFiles();
                break;

            default:
                let alertError = {
                    open: true,
                    severity: "error",
                    message:
                        "Ocurrió un error al iniciar el entrenamiento, por favor intenta de nuevo.",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
    };

    const handleSettingsChange = (targetName, targetValue) => {
        const { updateContextAttribute } = context;
        let settingsCopy = JSON.parse(JSON.stringify(settings));
        settingsCopy[targetName] = targetValue;

        //ADJUSTAMOS EL ROLE_SYSTEM con los valores de los campos
        const ROLE_SYSTEM = `
        Nombre y personalidad del asistente
        ${settingsCopy.ASSISTANT_NAME_PERSONALITY || ""} 

        Proporcionar instrucciones y contexto
        ${settingsCopy.INSTRUCTIONS_CONTEXT || ""} 

        Instrucciones para acciones prohibidas del asistente
        Si identificas que el cliente intenta hacer una solicitud prohibida, responderás con: ${
            settingsCopy.PROHIBITED_REQUESTS_INSTRUCTIONS || ""
        } 

        Ejemplos de respuesta
        ${settingsCopy.RESPONSE_EXAMPLES || ""} 

        Pregunta no encontrada
        Si la información no se encuentra disponible en la base de conocimientos, responderás con: ${
            settingsCopy.NOT_FOUND_QUESTION || ""
        } 

        ${settingsCopy.ADDITIONAL_NOTES || ""}

        `;
        settingsCopy.ROLE_SYSTEM = ROLE_SYSTEM;
        setSettings(settingsCopy);
        setHasChanges(true);
        updateContextAttribute("settingsHasChanges", true);
    };

    return (
        <div>
            {loading ? (
                <Grid item xs={12} className="center-loading-icon">
                    <CircularProgress />
                </Grid>
            ) : (
                <main className="genai-main">
                    {subscriptionModule.textGeneration && (
                        <Grid item xs={12}>
                            <div>
                                <h2>Configuración de generación de texto</h2>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: "15px",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div>
                                        <label className="top-label-field">
                                            Limitar las respuestas al contenido
                                            de los archivos
                                        </label>
                                        <Tooltip title="Selecciona si deseas que las respuestas generadas se limiten al contenido de los archivos de generación de texto">
                                            <HelpOutlineIcon
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                        <Checkbox
                                            name="LIMIT_CONTENT_DATA"
                                            checked={
                                                settings.LIMIT_CONTENT_DATA
                                            }
                                            onChange={(event) => {
                                                handleSettingsChange(
                                                    event.target.name,
                                                    event.target.checked
                                                );
                                            }}
                                            color="primary"
                                        />
                                    </div>
                                    <div>
                                        <label className="top-label-field">
                                            Nombre y personalidad del asistente
                                        </label>
                                        <Tooltip title="Define el nombre y la personalidad del asistente. Describe cómo debe interactuar, su tono de voz y estilo de comunicación para personalizar la experiencia del usuario. Este apartado influye en cómo el asistente se presenta y se comporta en las conversaciones.">
                                            <HelpOutlineIcon
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                        <TextField
                                            name="ASSISTANT_NAME_PERSONALITY"
                                            value={
                                                settings.ASSISTANT_NAME_PERSONALITY
                                            }
                                            onChange={(event) => {
                                                handleSettingsChange(
                                                    event.target.name,
                                                    event.target.value
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                    <div>
                                        <label className="top-label-field">
                                            Proporcionar instrucciones y
                                            contexto
                                        </label>
                                        <Tooltip title="Escribe las instrucciones generales y el contexto que el asistente debe seguir. Este texto servirá como los ajustes del sistema para guiar el comportamiento global del asistente.">
                                            <HelpOutlineIcon
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                        <TextField
                                            name="INSTRUCTIONS_CONTEXT"
                                            value={
                                                settings.INSTRUCTIONS_CONTEXT
                                            }
                                            onChange={(event) => {
                                                handleSettingsChange(
                                                    event.target.name,
                                                    event.target.value
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                    {/*<div>
                                        <label className="top-label-field">
                                            SISTEM ROLE DEBUG
                                        </label>
                                        <Tooltip title="Escribe las instrucciones generales y el contexto que el asistente debe seguir. Este texto servirá como los ajustes del sistema para guiar el comportamiento global del asistente.">
                                            <HelpOutlineIcon
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                        <TextField
                                            name="ROLE_SYSTEM"
                                            value={settings.ROLE_SYSTEM}
                                            onChange={(event) => {
                                                handleSettingsChange(
                                                    event.target.name,
                                                    event.target.value
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                        />
                                    </div>*/}

                                    <div>
                                        <label className="top-label-field">
                                            Instrucciones para acciones
                                            prohibidas del asistente
                                        </label>
                                        <Tooltip title="Especifica cómo el asistente debe manejar solicitudes inapropiadas o prohibidas. Proporciona directrices claras para responder de manera adecuada sin infringir políticas o dar información no permitida. Estas instrucciones forman parte esencial de los ajustes del sistema para asegurar el cumplimiento de normas.">
                                            <HelpOutlineIcon
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                        <TextField
                                            name="PROHIBITED_REQUESTS_INSTRUCTIONS"
                                            value={
                                                settings.PROHIBITED_REQUESTS_INSTRUCTIONS
                                            }
                                            onChange={(event) => {
                                                handleSettingsChange(
                                                    event.target.name,
                                                    event.target.value
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                        />
                                    </div>

                                    <div>
                                        <label className="top-label-field">
                                            Ejemplos de respuesta
                                        </label>
                                        <Tooltip title="Proporciona ejemplos específicos de cómo el asistente debe responder en distintas situaciones. Estos ejemplos ayudan a moldear el estilo y formato de las respuestas, sirviendo como referencia directa en los ajustes del sistema para mantener coherencia y calidad en las interacciones.">
                                            <HelpOutlineIcon
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                        <TextField
                                            name="RESPONSE_EXAMPLES"
                                            value={settings.RESPONSE_EXAMPLES}
                                            onChange={(event) => {
                                                handleSettingsChange(
                                                    event.target.name,
                                                    event.target.value
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                        />
                                    </div>

                                    <div>
                                        <label className="top-label-field">
                                            Pregunta no encontrada
                                        </label>
                                        <Tooltip title="Especifica el mensaje que el asistente debe mostrar cuando no pueda encontrar una respuesta a la pregunta del usuario. De esta forma, se mantiene la coherencia y la utilidad en las interacciones, aún si no se dispone de información concreta.">
                                            <HelpOutlineIcon
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                        <TextField
                                            name="NOT_FOUND_QUESTION"
                                            value={settings.NOT_FOUND_QUESTION}
                                            onChange={(event) => {
                                                handleSettingsChange(
                                                    event.target.name,
                                                    event.target.value
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                    <div>
                                        <label className="top-label-field">
                                            Notas Adicionales
                                        </label>
                                        <Tooltip title="Añade cualquier otra información relevante que pueda ayudar a definir el comportamiento del asistente. Esto puede incluir políticas específicas, referencias o consideraciones especiales que deben ser incluidas en los ajustes del sistema.">
                                            <HelpOutlineIcon
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                        <TextField
                                            name="ADDITIONAL_NOTES"
                                            value={settings.ADDITIONAL_NOTES}
                                            onChange={(event) => {
                                                handleSettingsChange(
                                                    event.target.name,
                                                    event.target.value
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                            </div>

                            <h2>Archivos para generación de texto</h2>
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "5px",
                                        }}
                                    >
                                        {trainingStatus.statusColor ===
                                        "spinner" ? (
                                            <div className="center-loading-icon">
                                                <CircularProgress />
                                            </div>
                                        ) : (
                                            <FiberManualRecordIcon
                                                style={{
                                                    color: trainingStatus.statusColor,
                                                }}
                                            />
                                        )}
                                        {trainingStatus.status}
                                        <br />
                                        {trainingStatus.statusColor ===
                                            "spinner" &&
                                            elapsedTime && (
                                                <span>{elapsedTime}</span>
                                            )}

                                        <Tooltip
                                            title={trainingStatus.statusDetail}
                                        >
                                            <HelpOutlineIcon
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <RefreshButton
                                            refreshFunction={refreshFunction}
                                        />
                                        <CustomButton
                                            disabled={
                                                trainingStatus.status !==
                                                    "Necesita entrenamiento" &&
                                                trainingStatus.status !==
                                                    "Error al procesar el archivo, inténtalo de nuevo."
                                            }
                                            variant="contained"
                                            onClick={() => trainSearchIndex()}
                                        >
                                            Entrenar
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                            <section className="settings-kb__table">
                                <header className="settings-kb__table-head">
                                    Archivos
                                </header>
                                {openAiFiles && openAiFiles.length > 0 ? (
                                    openAiFiles.map((item, i) => (
                                        <div
                                            className="settings-kb__table-body"
                                            key={i}
                                        >
                                            <div className="table-row" key={i}>
                                                <div className="table-cell">
                                                    <a
                                                        st
                                                        download
                                                        href={item.url}
                                                        key={i}
                                                    >
                                                        {item.name}
                                                    </a>
                                                </div>
                                                <Tooltip title="Eliminar archivo">
                                                    <IconButton
                                                        disabled={
                                                            trainingStatus.status ===
                                                                "Entrenamiento en proceso" ||
                                                            trainingStatus.status ===
                                                                "Subiendo URL"
                                                        }
                                                        color="secondary"
                                                        size="small"
                                                        onClick={() =>
                                                            deleteFileFromOpenAI(
                                                                item.name
                                                            )
                                                        }
                                                    >
                                                        <TrashCan
                                                            width="20px"
                                                            height="20px"
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="settings-kb__table-body empty">
                                        <div className="table-row">
                                            <div className="table-cell empty"></div>
                                        </div>
                                    </div>
                                )}
                            </section>

                            <div className="settings-kb__btn-row">
                                <Dialog
                                    open={openUploadDialog}
                                    onClose={() => {
                                        handleCloseDialog();
                                    }}
                                    disableBackdropClick
                                    disableEscapeKeyDown
                                >
                                    <DialogTitle
                                        disableTypography
                                        style={{ paddingRight: "30px" }}
                                    >
                                        <h2 style={{ paddingRight: "30px" }}>
                                            Cargar archivo de conocimiento
                                        </h2>
                                        <CloseButton
                                            onClose={() => handleCloseDialog()}
                                            modalTitle="¿Estás seguro que deseas salir?"
                                        >
                                            Los cambios realizados se perderán.
                                        </CloseButton>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Tooltip title="Decide si quieres cargar un archivo o extrar la información de un sitio web">
                                            <label>
                                                Selecciona el tipo de carga
                                            </label>
                                        </Tooltip>
                                        <TextField
                                            select
                                            name={"uploadType"}
                                            value={uploadType}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            onChange={(event) =>
                                                handleFileUpload(
                                                    event.target.name,
                                                    event.target.value
                                                )
                                            }
                                            error={uploadErrors.uploadType}
                                            helperText={
                                                uploadErrorHelper.uploadType
                                            }
                                        >
                                            {faqUploadTypes.length !== 0 ? (
                                                faqUploadTypes.map(
                                                    (option, i) => (
                                                        <MenuItem
                                                            key={i}
                                                            value={option.type}
                                                        >
                                                            {option.alias}
                                                        </MenuItem>
                                                    )
                                                )
                                            ) : (
                                                <MenuItem value={""}>
                                                    No hay opciones disponibles
                                                </MenuItem>
                                            )}
                                        </TextField>

                                        {uploadType === "file" && (
                                            <div>
                                                <DropzoneArea
                                                    name="uploadFile"
                                                    acceptedFiles={
                                                        openAIAcceptedFiles
                                                    }
                                                    dropzoneClass={
                                                        uploadErrors.uploadFile
                                                            ? "dropZoneContainerWithErrorNoWidth"
                                                            : "dropZoneContainerNoWidth"
                                                    }
                                                    filesLimit={1}
                                                    maxFileSize={20000000} //20 MB
                                                    showFileNames
                                                    dropzoneText={
                                                        "Arrastra y suelta un archivo aquí o haz click"
                                                    }
                                                    onChange={(file) => {
                                                        handleFileUpload(
                                                            "uploadFile",
                                                            file[0]
                                                        );
                                                    }}
                                                    dropzoneParagraphClass="dropzoneParagraph"
                                                    previewGridClasses={{
                                                        container:
                                                            "previewGridContainer",
                                                        item: "previewGridItem",
                                                        image: "previewGridImage",
                                                    }}
                                                    //Mensajes del Drop Zone
                                                    getFileAddedMessage={(
                                                        fileName
                                                    ) =>
                                                        `El archivo ${fileName} fue agregado con éxito`
                                                    }
                                                    getFileLimitExceedMessage={(
                                                        filesLimit
                                                    ) =>
                                                        ` Número máximo de archivos alcanzados. Solamente se permiten ${filesLimit} archivos `
                                                    }
                                                    getFileRemovedMessage={(
                                                        fileName
                                                    ) =>
                                                        ` El archivo ${fileName} ha sido eliminado. `
                                                    }
                                                    getDropRejectMessage={(
                                                        rejectedFile
                                                    ) =>
                                                        ` Este archivo ${rejectedFile.name} no es compatible o Excede el máximo permitido de 4MB `
                                                    }
                                                    alertSnackbarProps={{
                                                        anchorOrigin: {
                                                            vertical: "top",
                                                            horizontal: "right",
                                                        },
                                                    }}
                                                />
                                                {uploadErrors?.uploadFile && (
                                                    <span
                                                        style={{
                                                            fontSize: "0.75rem",
                                                            color: "red",
                                                        }}
                                                    >
                                                        {
                                                            uploadErrorHelper?.uploadFile
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                        {uploadType === "url" && (
                                            <div>
                                                <label className="top-label-field">
                                                    URL del archivo
                                                </label>
                                                <Tooltip
                                                    title={`Únicamente se cargará el HTML del sitio web, asegúrate de que el sitio web tenga la información que deseas cargar.`}
                                                >
                                                    <HelpOutlineIcon
                                                        color="primary"
                                                        fontSize="small"
                                                    />
                                                </Tooltip>
                                                <TextField
                                                    name="uploadURL"
                                                    value={uploadURL}
                                                    size="small"
                                                    type="small"
                                                    onChange={(event) =>
                                                        handleFileUpload(
                                                            event.target.name,
                                                            event.target.value
                                                        )
                                                    }
                                                    fullWidth
                                                    variant="outlined"
                                                    error={
                                                        uploadErrors.uploadURL
                                                    }
                                                    helperText={
                                                        uploadErrorHelper.uploadURL
                                                    }
                                                />
                                            </div>
                                        )}
                                    </DialogContent>
                                    <DialogActions
                                        style={{
                                            margin: 0,
                                            padding: "20px",
                                            justifyContent: "center",
                                            borderTop: "1px solid #e0e0e0",
                                        }}
                                    >
                                        <ButtonFilled
                                            disabled={
                                                uploadErrors.uploadFile ||
                                                uploadErrors.uploadURL
                                            }
                                            //dependiendo de donde fue invocado el dialogo, en confirmación lo regresamos a la función de invocación
                                            onClick={async () => {
                                                let askConfirm =
                                                    await Swal.fire({
                                                        title: "¿Estás seguro que deseas guardar?",
                                                        showDenyButton: true,
                                                        confirmButtonText: "Si",
                                                        denyButtonText: `No`,
                                                        confirmButtonColor:
                                                            "#27315d",
                                                        denyButtonColor:
                                                            "#27315d",
                                                    });
                                                //Cancelamos el guardado de cambios
                                                if (
                                                    askConfirm.isDenied ||
                                                    askConfirm.isDismissed
                                                ) {
                                                    return;
                                                }
                                                sendFilesToBlob();
                                            }}
                                            color="primary"
                                            autoFocus
                                        >
                                            Guardar
                                        </ButtonFilled>
                                    </DialogActions>
                                </Dialog>

                                <Tooltip title="Abrir modal para agregar archivo">
                                    <Button
                                        disabled={
                                            trainingStatus.statusColor ===
                                            "spinner" // si esta en proceso de entrenamiento
                                        }
                                        onClick={() => {
                                            setOpenUploadDialog(true);
                                        }}
                                        startIcon={
                                            <AgregarIcono
                                                width="15px"
                                                height="15px"
                                            />
                                        }
                                        size="small"
                                    >
                                        Agregar archivo
                                    </Button>
                                </Tooltip>
                            </div>
                        </Grid>
                    )}
                </main>
            )}
            <footer>
                <FooterControls
                    disableSave={!hasChanges}
                    disableCancel={!hasChanges}
                    onSave={() => onSave()}
                    onCancel={() => onCancel()}
                    showChatBtn={true}
                />
            </footer>
        </div>
    );
};

export default GenAiPage;
