import React from "react";

//Mui components
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

//Custom Portal Component
import theme from "./theme";
import MainMenu from "../containers/MainMenu";
import { AppProvider } from "../Context/AppContext";

//Styles
import "./App.css";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppProvider>
                <MainMenu />
            </AppProvider>
        </ThemeProvider>
    );
}

export default App;
